
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiMerchant } from "@/core/api";
import { setModuleBCN } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "offline-merchants-detail",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    
    const formData = ref({
      status: 10,
      name: "",
      short_key: "",
      last_validation_date: "",
      lifecycle_status: "",
      email: "",
      website: "",
      telephone: "",
      telephone2: "",
      fax: "",
      address: "",
      zip_code: "",
      city: "",
      country_iso_2: "",
      managing_directors: "",
      ut_commercial_registry: "",
      lei_register: "",
      vat_registration_number: "",
    });

    ApiMerchant.getCompanyInfo({ id: route.params.id })
      .then(({ data }) => {
        if (data.code == 0) {
          formData.value = data.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    onMounted(() => {
      setModuleBCN(t, route, router);
      MenuComponent.reinitialization();
    });

    return {
      formData,
    };
  },
});
